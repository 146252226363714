<template>
  <div
    v-if="data.fields"
    class="youtube-embed-wrapper"
  >
    <div class="youtube-embed-inner-wrapper" :class="`remove-padding-${data.fields.removePadding} reverse-order-${data.fields.reverseOrder}`">
      <div class="yt-embed" v-if="data.fields.youTubeEmbedCode" v-html="getVideoEmbed(data.fields.youTubeEmbedCode)"></div>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'YouTubeEmbed',

  transition: 'page',

  props: [ 'data' ],

  methods: {
    getVideoEmbed (ytID) {
      return `
        <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/${ytID}?autoplay=0&showinfo=0&iv_load_policy=3&modestbranding=0&origin&rel=0&controls=1&autohide=1${process.env.VUE_APP_BASE_URL}" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      `
    }
  },
}
</script>

<style scoped lang="scss">
.youtube-embed-wrapper {
  background: $color-wrapper-emphasis;
}
.youtube-embed-inner-wrapper {
  @include breakpoint($tablet) {
    max-width: 600px;
    margin: auto;
    padding-top: 10px;
  }
}
.yt-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
</style>
<style lang="scss">
.yt-embed iframe,
.yt-embed object,
.yt-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>